import { $t } from '@/i18n/index'

export const saleOptions = [
  {
    label: $t('button.dateTime'),
    value: 'DATETIME'
  },
  {
    label: $t('project.costBasis.journalNo'),
    value: 'JOURNAL_NO'
  },
  {
    label: $t('report.referenceNo'),
    value: 'REFERENCE_NO'
  },
  {
    label: $t('report.Quantity'),
    value: 'QUANTITY'
  },
  {
    label: $t('title.additionalItem'),
    value: 'ADDITIONAL_ITEM'
  }
]

export const inventoryOptions = [
  {
    label: $t('button.dateTime'),
    value: 'DATETIME'
  },
  {
    label: $t('project.costBasis.journalNo'),
    value: 'JOURNAL_NO'
  },
  {
    label: $t('report.referenceNo'),
    value: 'REFERENCE_NO'
  },
  {
    label: $t('report.Quantity'),
    value: 'QUANTITY'
  },
  {
    label: $t('title.additionalItem'),
    value: 'ADDITIONAL_ITEM'
  }
]

export const valuationFilterSaveType = {
  FUTURE: 'FUTURE',
  OPTIONS: 'OPTIONS'
}

export const rawDataFilterType = {
  ENDING: 'ENDING',
  REALIZED: 'REALIZED'
}

export const adjustmentOriginOptions = [
  {
    label: $t('common.exchange'),
    value: 'EXCHANGE'
  },
  {
    label: $t('common.client'),
    value: 'CLIENT'
  }
]

export const priceProviderTypeOptions = [
  {
    label: $t('valuation.price.coingeckoAPI'),
    svgName: 'coingecko-icon',
    value: 'API_COINGECKO'
  },
  {
    label: $t('valuation.price.exchangeRateAPI'),
    svgName: 'exchange-rate-icon',
    value: 'API_EXCHANGE_RATE'
  },
  {
    label: 'CSV',
    svgName: 'csv-file',
    value: 'CSV'
  }
]
